import createSagaMiddleware from "redux-saga";
import {
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import userPersistReducer from "./user/persistReducer";
import rootSaga from "./rootSaga";
import userReducer from "./user/reducers";

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  userReducer,
  userPersistReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userPersistReducer",
  ],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [sagaMiddleware];

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware,
    preloadedState,
  });
};

const preloadedState = {};
export const store = setupStore(preloadedState);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export const AppDispatch = store.dispatch;

export const Store = store;
