import React from "react";
import SocialMedia from "../../../components/socialmedia";
import LogoFooter from "../../../components/svg/logoFooter";
import Text from "../../../components/text";
import NavList from "../../../components/navList";
import { useSelector } from "react-redux";

const Footer = () => {
  const Year = new Date().getFullYear();
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  return (
    <footer className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="logo-footer mb-20">
              <LogoFooter />
            </div>
            <SocialMedia />
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-4">
                <Text type="H5" text="Company" className="ft-title" />
                <NavList
                  lists={[
                    {
                      id: "2",
                      text: "About Us",
                      url: "/about-us",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="copyright-wrapper">
              Copyright @{Year}
              <Text type="PARAGRAPH" text="" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
