import { useState } from "react";

const useHome = () => {
  const [selectedTransactionOption, setSelectedTransactionOption] =
    useState("");
  const handleTransactionOption = (selectedBuyCurrency) => {
    setSelectedTransactionOption(selectedTransactionOption);
  };

  return {
    selectedTransactionOption,
    handleTransactionOption,
  };
};

export default useHome;
