import React from "react";
import CloseIcon from "../svg/closeIcon";
import Button from "../button";

const Modal = ({ children, onClose, small, smallMedium, minHeight }) => {
  return (
    <div
      className={`modal-wrapper ${minHeight ? "modal-min-height" : ""} ${
        small ? "modal-small" : smallMedium ? "modal-small-medium" : ""
      }`}
    >
      <div className="modal-main-wrapper">
        <div className="modal-content-wrapper">
          {onClose && (
            <Button
              linkDark
              onClick={onClose}
              className="modal-close"
              text={<CloseIcon width={15} height={15} />}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
