import React from "react";
import Text from "../text";
import classes from "../../assets/css/Required.module.css";

const Input = ({
  secondary,
  mb0,
  label,
  noLabel,
  type,
  value,
  name,
  id,
  placeholder,
  onChange,
  readonly,
  disabled,
  required,
  autoFocus,
  maxlength,
  errormessage,
  mandatory,
}) => {
  return (
    <div
      className={`form-field form-control-hide-increment ${secondary ? "form-field-secondary" : ""} ${
        mb0 ? "mb-0" : ""
      }`}
    >
      {!noLabel && (
        <Text
          type="LABEL"
          text={label}
          className={`form-label ${mandatory ? classes.required : ""}`}
        />
      )}
      <input
        className={`form-control ${value ? "form-control-value" : ""} ${
          errormessage ? "error" : ""
        } `}
        type={type}
        name={name}
        value={value}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readonly}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
        maxLength={maxlength}
      />
      {errormessage && type != "checkbox" && (
        <div className="message-error">
          <small>{errormessage}</small>
        </div>
      )}
    </div>
  );
};

export default Input;
