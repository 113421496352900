import { createSlice } from "@reduxjs/toolkit";

const userReducerState = {
  isLoading: false,
  transactionDetails: null,
  platformCurrencies: null,
  exchangeRate: null,
  UserDetails: null,
  kycKeys: null,
  kycResult: null,
  platformFee: null,
  partnerDetails: null,
  profileDetails: null,
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState: userReducerState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },

    getUserOTPFetch: (state) => {
      state.isLoading = true;
    },
    getUserOTPSuccess: (state, action) => {
      state.isLoading = false;
    },
    getUserOTPFailure: (state) => {
      state.isLoading = false;
    },

    verifyUserOTPFetch: (state) => {
      state.isLoading = false;
    },
    verifyUserOTPSuccess: (state, action) => {
      state.isLoading = false;
      state.UserDetails = action.payload;
    },
    verifyUserOTPFailure: (state) => {
      state.isLoading = false;
    },

    getTransactionListFetch: (state) => {
      state.isLoading = true;
    },
    getTransactionListSuccess: (state, action) => {
      state.isLoading = false;
      state.transactionDetails = action.payload;
    },
    getTransactionListFailure: (state) => {
      state.isLoading = false;
    },
    getPlatformCurrenciesFetch: (state) => {
      state.isLoading = true;
    },
    getAllCurrenciesSuccess: (state, action) => {
      state.isLoading = false;
      state.platformCurrencies = action.payload;
    },
    getAllCurrenciesFailure: (state) => {
      state.isLoading = false;
    },
    getPlatformFeeFetch: (state) => {
      state.isLoading = true;
    },
    getPlatformFeeSuccess: (state, action) => {
      state.isLoading = false;
      state.platformFee = action.payload;
    },
    getPlatformFeeFailure: (state) => {
      state.isLoading = false;
    },
    getExchangeRatesFetch: (state) => {
      state.isLoading = true;
    },
    getExchangeRatesSuccess: (state, action) => {
      state.isLoading = false;
      state.exchangeRate = action.payload?.last;
    },
    getExchangeRatesFailure: (state) => {
      state.isLoading = false;
    },
    // fetch kyc keys
    kycKeysFetch: (state) => {
      state.isLoading = true;
    },
    kycKeysSuccess: (state, action) => {
      state.isLoading = false;
      state.kycKeys = action.payload;
    },
    kycKeysFailure: (state) => {
      state.isLoading = false;
      state.kycKeys = null;
    },
    updateKycStatusFetch: (state, action) => {
      state.isLoading = true;
      state.kycResult = action.payload;
    },
    updateKycStatusSuccess: (state) => {
      state.isLoading = false;
      state.kycKeys = null;
    },
    updateKycStatusFailure: (state) => {
      state.isLoading = false;
      state.kycKeys = null;
    },
    delectAcccountFetch: (state) => {
      state.isLoading = true;
    },
    deleteAccountSuccess: (state, action) => {
      state.isLoading = false;
      state.deletAccount = action.payload;
    },
    deleteAccountFailure: (state) => {
      state.isLoading = false;
    },
    getPartnerDetailsFetch: (state) => {
      state.isLoading = true;
    },
    getPartnerDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.partnerDetails = action.payload;
    },
    getPartnerDetailsFailure: (state) => {
      state.isLoading = false;
    },
    getProfileDetailsFetch: (state) => {
      state.isLoading = true;
    },
    getProfileDetailsSuccess: (state, action) => {
      state.isLoading = false;
      state.profileDetails = action.payload;
    },
    getProfileDetailsFailure: (state) => {
      state.isLoading = false;
    },
    logoutFetch: (state) => {
      state.isLoading = true;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      
    },
    logoutFailure: (state) => {
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserOTPFetch,
  getUserOTPSuccess,
  getUserOTPFailure,

  verifyUserOTPFetch,
  verifyUserOTPSuccess,
  verifyUserOTPFailure,

  getTransactionListFetch,
  getTransactionListSuccess,
  getTransactionListFailure,

  getPlatformCurrenciesFetch,
  getAllCurrenciesSuccess,
  getAllCurrenciesFailure,

  getExchangeRatesFetch,
  getExchangeRatesSuccess,
  getExchangeRatesFailure,

  kycKeysFetch,
  kycKeysFailure,
  kycKeysSuccess,

  updateKycStatusFailure,
  updateKycStatusFetch,
  updateKycStatusSuccess,

  getPlatformFeeFetch,
  getPlatformFeeSuccess,
  getPlatformFeeFailure,

  delectAcccountFetch,
  deleteAccountSuccess,
  deleteAccountFailure,

  getPartnerDetailsFetch,
  getPartnerDetailsSuccess,
  getPartnerDetailsFailure,

  getProfileDetailsFetch,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  
  logoutFetch,
  logoutSuccess,
  logoutFailure,
} = userSlice.actions;

export default userSlice.reducer;
