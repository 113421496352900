import React from "react";

const Logo = ({ width, height }) => {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250.26 61"
      width={width}
      height={height}
    >
      <defs></defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            class="cls-1"
            d="m0,0h11.38v60.38H0V0Zm6.85,36.79L31.06,0h14.33L8.31,51.58l-1.45-14.78Zm10.05-6.98l9.8-6.19,21.22,36.75h-13.5l-17.52-30.56Z"
          />
          <path
            class="cls-1"
            d="m55.94,58.8c-2.78-1.47-4.9-3.61-6.35-6.42-1.45-2.81-2.18-6.19-2.18-10.15v-7.02c0-3.9.73-7.25,2.18-10.03,1.45-2.78,3.57-4.9,6.35-6.35,2.78-1.45,6.14-2.18,10.07-2.18s7.29.73,10.07,2.18c2.78,1.45,4.9,3.57,6.35,6.35,1.45,2.78,2.18,6.12,2.18,10.03v7.14c0,3.93-.73,7.29-2.18,10.07-1.45,2.78-3.57,4.91-6.35,6.37-2.78,1.47-6.14,2.2-10.07,2.2s-7.29-.73-10.07-2.2Zm15.39-10.78c1.27-1.34,1.91-3.23,1.91-5.67v-7.14c0-2.41-.64-4.28-1.91-5.61-1.27-1.33-3.06-1.99-5.36-1.99s-4.04.66-5.29,1.99c-1.26,1.33-1.89,3.2-1.89,5.61v7.14c0,2.44.62,4.33,1.87,5.67s3.02,2.01,5.32,2.01,4.08-.67,5.36-2.01Z"
          />
          <path
            class="cls-1"
            d="m90.12,0h11.38v11.38h-11.38V0Zm0,17.27h11.38v43.1h-11.38V17.27Z"
          />
          <path
            class="cls-1"
            d="m107.83,17.27h11.38v43.1h-11.38V17.27Zm24.92,16.98c0-2.1-.6-3.74-1.81-4.9-1.21-1.16-2.91-1.74-5.13-1.74s-3.73.59-4.88,1.77c-1.15,1.18-1.72,2.83-1.72,4.96l-.87-9.55c1.3-2.71,2.86-4.75,4.67-6.1,1.81-1.36,4.09-2.04,6.83-2.04,4.54,0,8.06,1.53,10.55,4.59,2.49,3.06,3.74,7.38,3.74,12.98v26.16h-11.38v-26.12Z"
          />
          <path
            class="cls-1"
            d="m170.5,0h11.38v60.38h-11.38V0Zm5.19,25.99h19.31c1.36,0,2.55-.31,3.59-.93s1.85-1.5,2.43-2.64c.58-1.13.87-2.44.87-3.9s-.28-2.82-.85-3.97c-.57-1.15-1.37-2.03-2.41-2.66s-2.25-.93-3.63-.93h-19.31V0h19.02c3.76,0,7.08.77,9.95,2.3,2.87,1.54,5.09,3.7,6.67,6.5,1.58,2.8,2.37,6.04,2.37,9.72s-.79,6.91-2.37,9.7-3.8,4.94-6.67,6.46-6.18,2.28-9.95,2.28h-19.02v-10.96Z"
          />
          <path
            class="cls-1"
            d="m222.46,58.74c-2.95-1.51-5.2-3.71-6.75-6.6-1.55-2.89-2.33-6.38-2.33-10.44v-4.86c0-4.26.74-7.9,2.22-10.92,1.48-3.02,3.64-5.31,6.48-6.89,2.84-1.58,6.26-2.37,10.28-2.37,3.76,0,6.99.94,9.68,2.82,2.68,1.88,4.73,4.62,6.12,8.2,1.4,3.59,2.1,7.91,2.1,12.98v2.82h-29.48v-7.68h19.1l-.12-1c-.3-2.49-1.09-4.42-2.35-5.77s-2.94-2.04-5.04-2.04c-2.74,0-4.87.82-6.37,2.45-1.51,1.63-2.26,3.94-2.26,6.93v5.48c0,2.94.82,5.21,2.47,6.81,1.65,1.61,3.95,2.41,6.91,2.41,1.52,0,3.03-.27,4.53-.81,1.5-.54,2.85-1.31,4.07-2.3l7.43,6.6c-2.24,2.05-4.76,3.63-7.56,4.75-2.8,1.12-5.62,1.68-8.47,1.68-4.15,0-7.7-.75-10.65-2.26Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;

Logo.defaultProps = {
  width: "130",
  height: "20",
};
