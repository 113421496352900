import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
// components
import Logo from '../../../components/svg/logo';
import NavbarNav from '../../../components/navbarNav';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import Login from '../../authentication/login';
import VerificationCode from '../../authentication/verificationCode';
import AlertMessage from '../../../components/alertMessage';
import FollowFriendIcon from '../../../components/svg/followFriendIcon';
import AvatarProfileTop from '../../../components/avatarProfileTop';
import MenuIcon from '../../../components/svg/menuIcon';
// hooks
import useHeader from './hooks/header.hook';

const navbarListLoginAfter = [
  {
    id: '1',
    text: 'Home',
    url: '/',
  },
  {
    id: '2',
    text: 'History',
    url: '/history',
  },
  {
    id: '3',
    text: 'Profile',
    url: '/profile',
  },
];
const navbarListLoginBefore = [
  {
    id: '1',
    text: '',
    url: '',
  },
];

const Header = () => {
  const {
    isMobileWithTablet,
    isMenu,
    onToggleMenu,
    isOpenModal,
    onOpenLoginModal,
    onCloseModal,
    isVerify,
    onOpenVerify,
    VerifyUserOtpCallback,
    goToBackLogin,
  } = useHeader();

  const { userPhoneNumber } = useSelector((state) => state?.userPersistReducer);

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const { profileDetails } = useSelector((state) => state?.userReducer);

  let alertMessage;

  // Get the current location/route
  const location = useLocation();

  // Define an array of routes where you want to hide the header
  const routesToHideHeader = ['/profile'];

  // Check if the current route should hide the header
  const shouldHideHeader = routesToHideHeader.includes(location.pathname);

  let kycStatusMessage = '';

  const kycProfileData = profileDetails?.kycInfo?.kycStatus;
  if (kycProfileData) {
    alertMessage = false;
    const messages = {
      NOT_YET_STARTED:
        'Upload your KYC documents to start buying/selling your crypto',
      BASIC_KYC_PENDING:
        'Your account is under verification. We are trying to verify as soon as possible. Check your Profile for Further Updates',
      BASIC_KYC_REJECTED:
        'Your KYC has been rejected. Please try again by uploading your documents',
      FULL_KYC_PENDING:
        'Your video KYC is under verification. We are trying to verify as soon as possible. Check your Profile for Further Updates',
    };
    kycStatusMessage = messages[kycProfileData];
    if (
      isLoggedIn &&
      (kycProfileData === 'BASIC_KYC_PENDING' ||
        kycProfileData === 'FULL_KYC_PENDING')
    ) {
      alertMessage = true;
    } else {
      alertMessage = false;
    }
  }

  const config = {
    applicationId: "1b51099b-3ae5-438d-b557-32c59b37922f",
  };

  const callback = (response) => {
    switch(response.status) {
      case "USER_CLOSE":
          // user closed the session
        break;
      case "ERROR":
        // error in the sdk/application
        break;
      case "SUCCESS":
        // transaction success
        break;
      case "PENDING":
        // transaction pending (UTR/Transaction Id submitted) 
        break;
      case "FAILURE":
        // transaction got rejected
        break;
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://b2b-koinpe.payonramp.com/index.umd.js';
    script.async = true;
    script.onload = () => {
      const cpgInstance = new window.cpg.Instance(config);
      setPayonramp(cpgInstance);
    };

    document.head.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [payonramp, setPayonramp] = React.useState(null);

  const handlePayonramp = () => {
    if(payonramp) {
      payonramp.buy(callback, config);
    } else {
      console.error('CPG instance not available');
    }

  }

  return (
    <>
      <Helmet>
        <title>
          Efficient Fiat and Crypto Payment Gateway Services - PayOnRamp
        </title>
        <meta
          name="description"
          content="Discover seamless payment solutions with PayOnRamp's efficient fiat and crypto payment gateway services. Streamline transactions for your business and cater to both traditional and digital currency needs."
        />
        <link rel="canonical" href="https://payonramp.com/" />
      </Helmet>
      <header className="header-wrapper">
        <div className="container">
          <div className="header-main">
            <nav className="navbar-wrapper">
              <div className="nav-left">
                <div className="logo-wrp">
                  <Link to="/" className="logo-link">
                    <Logo />
                  </Link>
                </div>
              </div>
              {!isMobileWithTablet && (
                <NavComponement
                  isLoginAfter={isLoggedIn}
                  onOpenModal={onOpenLoginModal}
                />
              )}
              {isMobileWithTablet && (
                <div className='nav-header-right'>
                {isLoggedIn ? 
                  <Button
                    type="button"
                    onClick={handlePayonramp}
                    text="Buy/Sell Crypto"
                    className="nav-right-btn"
                  /> : ""}
                  <Button
                    type="button"
                    onClick={onToggleMenu}
                    icon={<MenuIcon close={isMenu} />}
                    linkPrimary
                    className="nav-toggle"
                  />
                </div>
              )}
            </nav>
            {isMobileWithTablet && isMenu && (
              <div className="nav-slide">
                <NavComponement
                  isLoginAfter={isLoggedIn}
                  isMobileWithTablet={isMobileWithTablet}
                  onOpenModal={onOpenLoginModal}
                />
              </div>
            )}
          </div>
        </div>
        {alertMessage && !shouldHideHeader && kycStatusMessage && (
          <AlertMessage
            icon={<FollowFriendIcon />}
            message={kycStatusMessage}
          />
        )}
      </header>
      {isOpenModal && (
        <Modal
          onClose={onCloseModal}
        >
          {isVerify ? (
            <VerificationCode
              mobileNumber={userPhoneNumber?.mobileNumber}
              goToBack={goToBackLogin}
              VerifyUserOtpCallback={VerifyUserOtpCallback}
            />
          ) : (
            <>
              <Login onOpenVerifyModal={onOpenVerify} />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default Header;

const NavComponement = (props) => {
  const { isLoginAfter, onOpenModal, isMobileWithTablet } = props;

  const config = {
    applicationId: "1b51099b-3ae5-438d-b557-32c59b37922f",
  };

  const callback = (response) => {
    switch(response.status) {
      case "USER_CLOSE":
          // user closed the session
        break;
      case "ERROR":
        // error in the sdk/application
        break;
      case "SUCCESS":
        // transaction success
        break;
      case "PENDING":
        // transaction pending (UTR/Transaction Id submitted) 
        break;
      case "FAILURE":
        // transaction got rejected
        break;
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://b2b-koinpe.payonramp.com/index.umd.js';
    script.async = true;
    script.onload = () => {
      const cpgInstance = new window.cpg.Instance(config);
      setPayonramp(cpgInstance);
    };

    document.head.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [payonramp, setPayonramp] = React.useState(null);

  const handlePayonramp = () => {
    if(payonramp) {
      payonramp.buy(callback, config);
    } else {
      console.error('CPG instance not available');
    }

  }

  return (
    <>
      <NavbarNav
        lists={isLoginAfter ? navbarListLoginAfter : navbarListLoginBefore}
      />
      <div className="nav-right">
        {isLoginAfter ? (
          !isMobileWithTablet ? (
            <div className="dp-wrapper">
              <Button
                type="button"
                onClick={handlePayonramp}
                text="Buy/Sell Crypto"
                className="nav-right-btn"
              />
              <button className="dp-heading invisible-btn">
                <AvatarProfileTop />
              </button>
            </div>
          ) : (
            <></>
          )
        ) : (
          <>
            <Button
              type="button"
              onClick={onOpenModal}
              text="Login"
              className="nav-right-btn"
            />
          </>
        )}
      </div>
    </>
  );
};
