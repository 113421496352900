import { PUBLIC_URL } from "./constants";

const API = {
  USER: {
    LOGIN: `${PUBLIC_URL}/user/portal/login/otp`,
    VERIFY_OTP: `${PUBLIC_URL}/user/portal/login/otp/verify`,
    GET_TRANSACTIONS_LIST: `${PUBLIC_URL}/user/portal/transactions`,
    UPDATE_KYC_STATUS: `${PUBLIC_URL}/user/auth/kyc/status`,
    PROFILE_DETAILS: `${PUBLIC_URL}/user/portal/profile`,
    LOGOUT: `${PUBLIC_URL}/user/portal/logout`,
  },
};
export default API;
