import axios from 'axios';
import { setupInterceptorsTo } from './axiosIntercptros';
import { PUBLIC_URL } from './constants';
import { store } from "../redux/store";
import { 
  clearUserLoginDetails,
} from "../redux/user/persistReducer";

/** Format URL */
const formatUrl = (url, params) => {
  const param =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : '';
  return `${url}${param}`;
};

const instance = setupInterceptorsTo(
  axios.create({
    baseURL: PUBLIC_URL,
    withCredentials: false,
  })
);

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const checkExpiry = async () => {
  if (
    Math.floor(new Date().getTime() / 1000) > localStorage.getItem('expiry')
    && localStorage.getItem('expiry')
  ) {
    localStorage.setItem('callApi', true);
    await delay(100);
  }

  if (localStorage.getItem('callApi') == 'true') {
    localStorage.setItem('callApi', false);
    try {
      const { data } = await axios.post(
        `${PUBLIC_URL}/user/auth/generateNewRefreshToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('refreshToken')}`,
          },
        }
      );
      localStorage.setItem('accessToken', data.data.accessAuthToken);
      localStorage.setItem('refreshToken', data.data.refreshAuthToken);
      localStorage.setItem('expiry', data.data.expiry);
    }
    catch(error) {
      store.dispatch(clearUserLoginDetails());
    }
  }
}
/** POST Request */
export const httpPost = async (url, data, params = {}) => {
  await checkExpiry();
  await delay(100);

  return new Promise((resolve) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    instance
      .post(formatUrl(url, params), data, { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          resolve(error.response);
        } else {
          resolve(error);
        }
      });
  });
};

/** GET Request */
export const httpGet = async (url, params) => {
  await checkExpiry();
  await delay(100);

  return new Promise((resolve) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    instance
      .get(formatUrl(url, params), { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          resolve(error.response);
        } else {
          resolve(error);
        }
      });
  });
};

/** PUT Request */
export const httpPut = async (url, data, params = {}) => {
  await checkExpiry();
  await delay(100);

  return new Promise((resolve) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    instance
      .put(formatUrl(url, params), data, { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          resolve(error.response);
        } else {
          resolve(error);
        }
      });
  });
}

/** PATCH Request */
export const httpPatch = async (url, data, params = {}) => {
  await checkExpiry();
  await delay(100);

  return new Promise((resolve) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    instance
      .patch(formatUrl(url, params), data, { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          resolve(error.response);
        } else {
          resolve(error);
        }
      });
  });
}

/** DELETE Request */
export const httpDelete = async (url, params) => {
  await checkExpiry();
  await delay(100);

  return new Promise((resolve) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    };
    instance
      .delete(formatUrl(url, params), { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          resolve(error.response);
        } else {
          resolve(error);
        }
      });
  });
}
