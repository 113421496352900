import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import Section from '../../../components/section';
import Text from '../../../components/text';
import Flex from '../../../components/flex';
import Image from '../../../components/image';
import ResetIcon from '../../../components/svg/resetIcon';
import Button from '../../../components/button';
import ProgressBar from '../../../components/progressBar';
import KYCDetailCard from '../../../components/kycDetailCard';
// redux
import {
   getProfileDetailsFetch,
} from '../../../redux/user/reducers';
// assests
import AvatarProfileImg from '../../../assets/img/avatar-profile-img.svg';
import ApprovedImg from '../../../assets/img/profile_approved.png';
//utils
import { truncate } from '../../../utils/util';

const ProfileView = () => {
  const dispatch = useDispatch();
  const { profileDetails } = useSelector((state) => state?.userReducer);

  const { isLoggedIn } = useSelector(
    (state) => state.userPersistReducer
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getProfileDetailsFetch());
    }
  }, [isLoggedIn]);

  let kycStatusMessage = '';

  const kycProfileData = profileDetails?.kycInfo?.kycStatus;
  if (kycProfileData) {
    const messages = {
      BASIC_KYC_PENDING: 'Your account is under verification.',
      BASIC_KYC_REJECTED: 'Your KYC has rejected.',
      BASIC_KYC_SUCCESS: 'Your Basic KYC is done',
      NOT_YET_STARTED: 'Your KYC has not done, Please submit KYC for BUY/SELL'
    };
    kycStatusMessage = messages[kycProfileData] ?? messages['NOT_YET_STARTED'];
  }

  let maxAmount = profileDetails?.kycInfo?.isFullKycVerified
    ? profileDetails?.kycInfo?.fullKycMonthlyLimit
    : profileDetails?.kycInfo?.basicKycMonthlyLimit;
  let percentage = (
    (profileDetails?.transactionInfo?.monthlyBuyOrderSum / maxAmount || 0) * 100
  ).toFixed(1);

  let Amount = truncate(profileDetails?.transactionInfo?.monthlyBuyOrderSum || 0)

  return (
    <>
      <Section containerWidth1100 className="pt-0">
        <div className="profile-wrapper">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-6">
              <div className="profile-info-wrp">
                <Flex flexColumn>
                  <div className="profile-info-img">
                    <Image
                      src={AvatarProfileImg}
                      alt="Rahul Sharma"
                      width="100"
                      height="100"
                    />
                  </div>
                  <>
                    {profileDetails?.userInfo?.fullName ? (
                      <Text
                        type="H3"
                        className="profile-info-title"
                        text={profileDetails?.userInfo?.fullName}
                      />
                    ) : (
                      <Text
                        type="H3"
                        className="profile-info-title"
                        text="Your profile"
                      />
                    )}
                    {profileDetails?.userInfo?.phoneNumber ? (
                      <p className="profile-info-desc">
                        {profileDetails?.userInfo?.phoneNumber}
                      </p>
                    ) : (
                      <></>
                    )}
                  </>
                </Flex>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-6">
              <Flex flexColumn>
                <div className="depositlimits-wrp">
                  <Flex
                    alignItems="center"
                    justifyContent="between"
                    className="depositlimits-first-line"
                  >
                    <Text
                      type="H6"
                      className="depositlimits-title"
                      text="Monthly deposit limits"
                    />
                    <Button
                      type="button"
                      linkPrimary
                      icon={<ResetIcon width={12} height={12} />}
                      onClick={() =>
                        dispatch(getProfileDetailsFetch())
                      }
                    />
                  </Flex>
                  <ProgressBar
                    percent={percentage > 100 ? 100 : percentage}
                    balance={Amount}
                    maxAmount={maxAmount}
                  />
                </div>
              </Flex>
              <Flex flexColumn>
                <div className="row row-mrl-6">
                  <div className="col-12 col-md-12">
                    <KYCDetailCard
                      active
                      text="Basic KYC"
                      action={
                        <>
                          <Flex className="profile-kyc-status">
                            {kycProfileData === 'BASIC_KYC_SUCCESS' ? (
                              <Image
                                src={ApprovedImg}
                                alt="Kyc Success"
                              />
                            ) : (
                              ''
                            )}
                            <Text type={'PARAGRAPH'} text={kycStatusMessage} />
                          </Flex>
                        </>
                      }
                      detailList={[
                        {
                          title: 'Requirement',
                          list: ['Mobile number', 'Official valid document'],
                        },
                        {
                          title: 'Transaction limits',
                          list: ['10,000/ transaction', '1,00,000/ month'],
                        },
                      ]}
                    />
                  </div>
                </div>
              </Flex>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default ProfileView;
