import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';

const useHeader = () => {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const isMobileWithTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  const [isMenu, setIsMenu] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUploadKYC, setIsUploadKYC] = useState(false);
  const [isLoginAfter, setIsLoginAfter] = useState(isLoggedIn ? true : false);

  useEffect(() => {
    if (isLoggedIn === true) {
      setIsLoginAfter(true);
    }
  }, [isLoggedIn]);

  const onToggleMenu = () => {
    setIsMenu((state) => !state);
  };

  const onOpenLoginModal = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
    setIsVerify(false);
  };

  const onOpenVerify = () => {
    setIsVerify(true);
  };

  const goToBackLogin = () => {
    setIsVerify(false);
  };

  const goToBackVerificationCode = () => {
    setIsUploadKYC(false);
  };

  const VerifyUserOtpCallback = (verifyOtpResponse) => {
    if (verifyOtpResponse.responseStatus === true) {
      if (isOpenModal === true) {
        navigate("/profile");
      }
    }
  };

  return {
    isMobileWithTablet,
    isMenu,
    setIsMenu,
    onToggleMenu,
    isOpenModal,
    onOpenLoginModal,
    onCloseModal,
    isVerify,
    onOpenVerify,
    isUploadKYC,
    goToBackLogin,
    goToBackVerificationCode,
    isLoginAfter,
    VerifyUserOtpCallback,
  };
};

export default useHeader;
