import React from 'react';
import { useMediaQuery } from 'react-responsive';
// components
import Accordion from '../../../components/accordion';
import Flex from '../../../components/flex';
import Image from '../../../components/image';
import Section from '../../../components/section';
import SeizeCard from '../../../components/seizeCard';
import ServiceCard from '../../../components/serviceCard';
import CurrencyConversionIcon from '../../../components/svg/service/currencyConversionIcon';
import CustomerSupportIcon from '../../../components/svg/service/customerSupportIcon';
import FlexibleSettlementIcon from '../../../components/svg/service/flexibleSettlementIcon';
import PricingIcon from '../../../components/svg/service/pricingIcon';
import SettingIcon from '../../../components/svg/service/settingIcon';
import Text from '../../../components/text';
// images
import CryptocurrencyImg from '../../../assets/img/cryptocurrency.png';
import SeizeImgOne from '../../../assets/img/seize-img-1.png';
import SeizeImgTwo from '../../../assets/img/seize-img-2.png';
import SeizeImgThree from '../../../assets/img/seize-img-3.png';
import SeizeImgFour from '../../../assets/img/seize-img-4.png';

const faqList = [
  {
    question: 'What is Koin.pe?',
    answer: (
      <ul>
        <li>
        Koin.pe is a comprehensive fiat-to-crypto payment gateway. It addresses the crucial need of enabling easy access to cryptocurrencies and blockchain technologies for the general public and businesses. Koin.pe achieves this by seamlessly integrating global compliance, diverse payment methods, and extensive liquidity from across the world.
        </li>
      </ul>
    ),
  },
  {
    question: 'What Cryptocurrencies Does Koin.pe Support?',
    answer: (
      <ul>
        <li>
        Koin.pe offers support for an extensive range of over 150+ cryptocurrencies. Our current lineup includes major ones on BTC, TRX, ERC, BSC, and Polygon Blockchains.
        </li>
      </ul>
    ),
  },
  {
    question: 'What Payment Methods Does Koin.pe Accept?',
    answer: (
      <ul>
        <li>
        Koin.pe accommodates various payment methods for INR transactions:
          <ul>
            <li>
            UPI (Google Pay, Phone Pe): Conveniently pay using UPI-based services like Google Pay and Phone Pe.
            </li>
            <li>Bank Transfer: Opt for secure payments via direct bank transfers.</li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    question: "How Can I Contact Koin.pe's Support Team?",
    answer: (
      <ul>
        <li>
        For any assistance or inquiries, you can reach our support team through:
          <ul>
            <li>Email: Drop us a line at support@koin.pe.</li>
            <li>Support Ticket: Visit our Support Page and initiate a support ticket for a prompt response.</li>
          </ul>
        </li>
      </ul>
    ),
  },
];

const HomeView = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <>
      <Section className="section-hero">
        <div className="row">
          <div className="col-10 col-md-8 mx-auto">
            <Text
              type="H1"
              className="hero-title"
              text={
                <>
                  Koin.pe: <strong>Revolutionizing Transactions.</strong>
                </>
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-10 col-md-4 mx-auto">
            <Text
              type="PARAGRAPH"
              className="hero-desc"
              text=" Seamlessly accept both fiat and crypto payments, merging tradition with innovation."
            />
          </div>
        </div>
      </Section>
      <Section className="section-service">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="mb-5"
          flexColumn={isMobile}
        >
          <Flex flexColumn className="col-10 col-md-7 col-lg-4 px-0">
            <Text
              type="H2"
              text="Experience the Extraordinary with Koin.pe. Discover Why."
            />
          </Flex>
        </Flex>
        <div className="row row-mrl-5 flex-column flex-md-row">
          <div className="col service-item">
            <ServiceCard
              text="Cutting-Edge Integration Solutions"
              icon={<SettingIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              odd
              text="Clear, Competitive Pricing"
              icon={<PricingIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              text="Simplified Currency Exchange"
              icon={<CurrencyConversionIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              odd
              text="Versatile Settlement Choices"
              icon={<FlexibleSettlementIcon />}
            />
          </div>
          <div className="col service-item">
            <ServiceCard
              text="Outstanding Customer Service"
              icon={<CustomerSupportIcon />}
            />
          </div>
        </div>
      </Section>
      <Section className="section-seize">
        <div className="row mb-5">
          <div className="col-12 col-md-7 col-lg-5 mx-auto text-center">
            <Text
              type="H2"
              text="Unlock New Horizons and Elevate Your Business"
            />
            <Text
              type="PARAGRAPH"
              text="with Koin.pe’s Swift, Secure, and Straightforward Payment Solutions."
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-11 col-lg-9 mx-auto">
            <div className="row">
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-one"
                  icon={SeizeImgOne}
                  title="India’s Foremost Advanced Payment Gateway"
                  description="Offer your customers a distinct choice of payment via both Fiat and Crypto!"
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-two"
                  icon={SeizeImgTwo}
                  title="Robust and Resilient Security"
                  description="Rely on Koin.pe for a secure and trustworthy platform for all your fiat and crypto transactions."
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-three"
                  icon={SeizeImgThree}
                  title="Broad Range of Cryptocurrency Support"
                  description="Koin.pe embraces a diverse spectrum of cryptocurrencies, including Bitcoin, Ethereum, Litecoin, Ripple, and more."
                />
              </div>
              <div className="col-12 col-md-6">
                <SeizeCard
                  className="seize-four"
                  icon={SeizeImgFour}
                  title="Rapid Settlements"
                  description="Experience swift settlements with Koin.pe, keeping your funds readily accessible for business operations."
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section className="section-whous">
        <div className="row">
          <div className="col-12 col-md-4">
            <Flex flexColumn className="whous-left">
              <Text type="H2" text="Who Can Benefit from Koin.pe?" />
              <Text
                type="PARAGRAPH"
                className="mb-5"
                text="Koin.pe is designed for users who seek to both buy and sell with ease and efficiency."
              />
              <Text
                type="PARAGRAPH"
                className="mb-0 font-size-14"
                text="Whether you're an individual looking to trade cryptocurrencies or a business aiming to integrate crypto transactions, Koin.pe facilitates a seamless exchange experience for all."
              />
            </Flex>
          </div>
          <div className="col-12 col-md-8 d-flex justify-content-end">
            <Image src={CryptocurrencyImg} alt="" />
          </div>
        </div>
      </Section>
      <Section className="section-faqs">
        <div className="row">
          <div className="col-12 col-md-8">
            <Text type="H2" text="Frequently asked questions" />
            <Accordion accordionList={faqList} />
          </div>
        </div>
      </Section>
    </>
  );
};
export default HomeView;
