import React from 'react';
import {  useNavigate } from "react-router-dom";

// components
import Section from '../../../components/section';
import Flex from '../../../components/flex';
import Image from '../../../components/image';
import Text from '../../../components/text';
import MissionBreakBarrierCard from '../../../components/MissionBreakBarrierCard';

// images
import AboutusImg from '../../../assets/img/hero-aboutus-img.png';
import HowweplanImg from '../../../assets/img/safety-money-img.png';
import EvolutionImg from '../../../assets/img/evolutionary-experience-img.png';
import CommunicatingEffectivelyImg from '../../../assets/img/communication-effectively-img.png';
import NationwideImg from '../../../assets/img/inclusivity-nationwide-img.png';

const AboutusView = () => {
  const navigate = useNavigate();

  return (
    <>
      <Section containerWidth1100 className="section-hero-aboutus">
        <Flex flexColumn>
          <div className="row d-flex justify-content-between flex-column-reverse flex-md-row">
            <div className="col-12 col-md-5">
              <div className="hero-aboutus-left">
                <div className="hero-aboutus-img">
                  <Image src={AboutusImg} alt="Image" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="hero-aboutus-caption">
                <Text
                  type="H1"
                  className="hero-caption-title"
                  text="Welcome to Koin.pe - Your Premier Crypto Gateway!"
                />
                <Text
                  type="PARAGRAPH"
                  text="Elevate your business into the future with Koin.pe, your go-to solution for integrating cryptocurrencies and fiat transactions. Our advanced platform offers a dynamic approach, empowering businesses large and small to navigate the realms of both cryptocurrencies and traditional fiat money. Ideal for online merchants and service providers alike, Koin.pe stands as your ally in capitalizing on the vast potential of digital currencies."
                />
                <Text
                  type="PARAGRAPH"
                  text="While crypto integration is a new frontier for many, Koin.pe is dedicated to leading the charge. We're here to revolutionize your digital transaction experience, guiding you through the benefits and methods of effective crypto utilization."
                />
              </div>
            </div>
          </div>
        </Flex>
      </Section>
      <Section containerWidth1100 className="section-how-we-plan">
        <div className="row align-items-center">
          <div className="col-12 col-md-7">
            <Flex flexColumn className="section-content-left">
              <Text
                type="H2"
                className="font-size-36 font-weight-700 mb-1"
                text="Here's Our Strategy:"
              />
              <Text
                type="H5"
                className="text-pink mb-4"
                text="Educate, Secure, and Connect!"
              />
              <p className="mb-4">
                <strong>
                  <i>Educate</i>
                </strong>{' '}
                by raising awareness about digital transactions and the advantages of crypto integration. As the world progresses, it's crucial to adopt innovative technologies and stay ahead.
              </p>
              <p className="mb-4">
                <strong>
                  <i>Secure</i>
                </strong>{' '}
                by providing safe and transparent transactions. We prioritize protecting user data and ensuring confidentiality.
              </p>
              <p className="mb-0">
                <strong>
                  <i>Connect</i>
                </strong>{' '}
                by forging meaningful partnerships and collaborations, fostering a network that expands the scope of crypto transactions.
              </p>
            </Flex>
          </div>
          <div className="col-12 col-md-5">
            <div className="section-right-image">
              <Image src={HowweplanImg} alt="Image" />
            </div>
          </div>
        </div>
      </Section>
      <Section containerWidth1100 className="section-mission-break-barriers">
        <div className="row mb-5">
          <div className="col-12 col-md-9 mx-auto text-center">
            <Text
              type="H2"
              className="section-title font-weight-700"
              text="Our Mission: Smoothing the Path to Success!"
            />
            <Text
              type="PARAGRAPH"
              text="Koin.pe's mission is to deliver an effortlessly smooth user experience that catalyzes business growth. We employ a customer-focused strategy, tailored to various audiences, making crypto more accessible and user-friendly."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={EvolutionImg}
              text="Creating a Revolutionary Experience:"
              description="We are committed to transforming usability, simplifying complex processes to make navigating the Web3 space effortless for everyone."
            />
          </div>
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={CommunicatingEffectivelyImg}
              text="Effective Communication:"
              description="Our aim is to reach out to India's diverse population with strategic communication methods. We're set on highlighting the importance and potential of Web3, engaging and empowering individuals with experiences that resonate broadly."
            />
          </div>
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={NationwideImg}
              text="Nationwide Inclusivity:"
              description="Koin.pe's ultimate vision is to bring Web3 to every part of India. We strive to dismantle barriers and open up equal opportunities for all to explore the decentralized web's exciting prospects."
            />
          </div>
        </div>
        <div className="joinus-wrp">
          <Text
            type="H2"
            className="joinus-title"
            text="Join Koin.pe on this transformative path, as we lead a Web3 revolution that empowers individuals and businesses alike, leaving no one behind."
          />
        </div>
      </Section>
    </>
  );
};

export default AboutusView;
