import { put, takeEvery, call } from "redux-saga/effects";
import { STATUS_CODE } from "../../utils/constants";
import { httpGet, httpPost } from "../../utils/axiosConnect";
import API from "../../utils/api";
import {
  saveUserLoginDetails,
  saveOTPReferenceDetails,
  savePhoneNumber,
  saveLoginDetails,
  clearUserLoginDetails,
} from "./persistReducer";
import { toasts } from "../../utils/toast";
import {
  getUserOTPSuccess,
  getUserOTPFailure,
  verifyUserOTPSuccess,
  verifyUserOTPFailure,
  updateKycStatusSuccess,
  updateKycStatusFailure,
  getTransactionListSuccess,
  getTransactionListFailure,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  logoutSuccess,
  logoutFailure,
} from "./reducers";

function* getUserOTPSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.LOGIN, payload);
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getUserOTPSuccess(response.data.data.otpReferenceKey));
      yield put(
        saveOTPReferenceDetails({
          otpReferenceKey: response.data.data.otpReferenceKey,
        })
      );
      yield put(
        savePhoneNumber({
          mobileNumber: payload?.phonenumber,
        })
      );
      payload?.callback(response);
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden
    ) {
      yield put(getUserOTPFailure());
    } else {
      yield put(getUserOTPFailure());
    }
  } catch (error) {
    yield put(getUserOTPFailure());
  }
}

function* verifyUserOTPSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.VERIFY_OTP, payload?.values);
    if (response?.status === STATUS_CODE?.successful) {
      localStorage.setItem('accessToken', response.data.data.accessToken);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      localStorage.setItem('expiry', response.data.data.expiry);
      yield put(verifyUserOTPSuccess(response.data.data));
      yield put(saveOTPReferenceDetails(null));
      yield put(saveUserLoginDetails());
      toasts.success(response?.data?.message);
      payload.callback({
        responseStatus: true,
        isKYCApprovedUser: response.data.data.isKYCApprovedUser,
      });
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden ||
      response?.status === STATUS_CODE.badRequest
    ) {
      yield put(verifyUserOTPFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, isKYCApprovedUser: null });
    } else {
      yield put(verifyUserOTPFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, isKYCApprovedUser: null });
    }
  } catch (error) {
    yield put(verifyUserOTPFailure());
    payload.callback({ responseStatus: false, isKYCApprovedUser: null });
  }
}

function* transactionsListSaga({ payload }) {
  try {
    const response = yield httpGet(
      `${API.USER.GET_TRANSACTIONS_LIST}`,
      payload?.body
    );

    if (response?.status === STATUS_CODE?.successful) {
      yield put(getTransactionListSuccess(response?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getTransactionListFailure());
    } else {
      yield put(getTransactionListFailure());
    }
  } catch (error) {
    yield put(getTransactionListFailure());
  }
}

function* updateKycStatusSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.UPDATE_KYC_STATUS}`,
      payload.values
    );
    if (
      response?.status === STATUS_CODE?.successful &&
      response?.data?.status === "success"
    ) {
      yield put(updateKycStatusSuccess(response?.data));
      payload.callback(payload.values.status);
      yield put(
        saveLoginDetails({
          ...response?.data?.data,
          userInfo: true,
          kycApproved: true,
        })
      );
      yield call(getProfileDetailSaga);

    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(updateKycStatusFailure());
      payload.callback("failed");
    } else {
      yield put(updateKycStatusFailure());
      payload.callback(payload.values.status);
      yield call(getProfileDetailSaga);
    }
  } catch (error) {
    yield put(updateKycStatusFailure());
    payload.callback("failed");
  }
}

function* getProfileDetailSaga(payload) {
  try {
    const response = yield httpGet(
      `${API.USER.PROFILE_DETAILS}`,
      {}
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getProfileDetailsSuccess(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getProfileDetailsFailure());
    } else {
      yield put(getProfileDetailsFailure());
    }
  } catch (error) {
    yield put(getProfileDetailsFailure());
  }
}

function* logOutSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.LOGOUT, {});
    if (response?.status === STATUS_CODE?.successful) {
      yield put(logoutSuccess(response.data));
      payload.logoutCallback();
      yield put(clearUserLoginDetails());
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE?.forbidden
    ) {
      yield put(logoutFailure());
      yield put(clearUserLoginDetails());
    } else {
      yield put(logoutFailure());
      yield put(clearUserLoginDetails());
    }
  } catch (error) {
    yield put(logoutFailure());
    yield put(clearUserLoginDetails());
  }
}

function* userSaga() {
  yield takeEvery("userReducer/getUserOTPFetch", getUserOTPSaga);
  yield takeEvery("userReducer/verifyUserOTPFetch", verifyUserOTPSaga);
  yield takeEvery("userReducer/getTransactionListFetch", transactionsListSaga);
  yield takeEvery("userReducer/updateKycStatusFetch", updateKycStatusSaga);
  yield takeEvery("userReducer/getProfileDetailsFetch", getProfileDetailSaga);
  yield takeEvery("userReducer/logoutFetch", logOutSaga);
}
export default userSaga;
