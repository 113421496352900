import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-phone-number-input/style.css";
import "./assets/scss/index.scss";
import { useSelector, useDispatch } from "react-redux";
// page
import Home from "./pages/home";
import History from "./pages/history";
import Profile from "./pages/profile";
import AboutUs from "./pages/aboutus";

import {
  logoutFetch,
} from "./redux/user/reducers";

let idleTime = 0;
let idleInterval;
function App() {
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    document.body.classList.add("body");
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      document.getElementById('app').addEventListener("mousemove", () => {
        idleTime = 0;
      });

      function logout() {
        idleTime ++;
        if (idleTime >= 10) {
          clearInterval(idleInterval);
          idleTime = 0;
          document.getElementById('app').removeEventListener("mousemove", () => {}, false);
          dispatch(logoutFetch());
        }
      }

      clearInterval(idleInterval);
      idleInterval = setInterval(logout, 60000);
    }
  }, [isLoggedIn])

  return (
    <div className="App" id="app">
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index={true} element={<Home />} />
            <Route path="history" element={isLoggedIn ? <History /> : <Navigate to="/" replace />} />
            <Route path="profile" element={isLoggedIn ? <Profile /> : <Navigate to="/" replace />} />
            <Route path="about-us" element={<AboutUs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
